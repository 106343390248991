<template>
  <div>
    <b-alert variant="dark" class="mb-2" show d-block>
      <h4 class="alert-heading">Como funciona?</h4>
      <div class="alert-body">
        O desconto será aplicado sobre o preço do produto. Atualmente, ao
        cadastrar o produto, existem 2 campos de precificação, que são: Preço e
        Promocional. Também serão cadastrados, o período promocional em cada
        produto.
      </div>
    </b-alert>

    <b-card no-body class="mb-0">
      <b-table
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="fields"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
      >
        <template #cell(title)="data">
          <b-icon
            style="width: 9px"
            icon="circle-fill"
            :class="data.item.active ? 'text-success' : 'text-danger'"
          />
          {{ data.item.title }}
        </template>
        <template #cell(products)="data">
          <b-badge class="d-block" variant="light-secondary">
            {{ JSON.parse(data.item.products).length }}
          </b-badge>
        </template>
        <template #cell(discount)="data">
          <b-badge class="d-block" variant="light-info">
            {{ data.item.discount }}%
          </b-badge>
        </template>
        <template #cell(start)="data">
          {{ data.item.start | datePT(true) }} à
          {{ data.item.end | datePT(true) }}
        </template>
        <template #cell(action)="data">
          <feather-icon
            icon="EditIcon"
            size="16"
            class="cursor-pointer cursor"
            v-if="$can('Campanha - Editar', 'Comercial')"
            @click="openModalDetails(data.item.uuid)"
          />
        </template>
      </b-table>
      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
      />
    </b-card>

    <b-modal
      id="modal-create-coupon-mass"
      title="Agendar desconto em massa"
      cancel-title-html="Fechar"
      no-close-on-backdrop
      size="lg"
      hide-footer
    >
      <b-form @submit.prevent="submitApplyDiscount">
        <b-card-text>
          <div class="form-row">
            <div class="col-md-3 col-6">
              <label>
                <i class="text-danger bi bi-record-circle"></i>
                Categoria
              </label>
              <div class="form-group">
                <v-select
                  label="title"
                  item-text="title"
                  item-value="id"
                  v-model="discount.category"
                  :options="optionsCategories"
                  @input="changeCategory"
                  :class="{ 'is-invalid': $v.discount.category.$error }"
                >
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>

            <div class="col-md-3 col-6">
              <label>
                <i class="text-danger bi bi-record-circle"></i>
                Desconto (%)
              </label>
              <div class="form-group">
                <input
                  type="number"
                  v-model="discount.discount"
                  :class="{ 'is-invalid': $v.discount.discount.$error }"
                  max="99"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Início da exibição
                </label>
                <flat-pickr
                  v-model="discount.start"
                  class="form-control"
                  :class="{ 'is-invalid': $v.discount.start.$error }"
                  :config="{
                    dateFormat: 'd/m/Y H:i',
                    altFormat: 'Y-m-d',
                    locale: 'pt',
                    enableTime: true,
                    defaultHour: 0,
                    minuteIncrement: 1,
                    time_24hr: true,
                    wrap: true,
                  }"
                />
              </div>
            </div>
            <div class="col-md-3">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Fim da exibição
              </label>
              <div class="form-group">
                <flat-pickr
                  v-model="discount.end"
                  class="form-control"
                  :class="{ 'is-invalid': $v.discount.end.$error }"
                  :config="{
                    dateFormat: 'd/m/Y H:i',
                    locale: 'pt',
                    enableTime: true,
                    defaultHour: 23,
                    defaultMinute: 59,
                    minuteIncrement: 1,
                    time_24hr: true,
                    wrap: true,
                  }"
                />
              </div>
            </div>

            <div class="col-md-12">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Título
              </label>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="discount.title"
                  :class="{ 'is-invalid': $v.discount.title.$error }"
                />
              </div>
            </div>
          </div>

          <p v-if="loadingProducts">
            <b-spinner label="Spinning"></b-spinner>
            Carregando produtos...
          </p>

          <div
            v-if="!loadingProducts"
            class="form-row"
            :class="products.length ? 'mt-2' : ''"
            :style="`${products.length ? 'max-height: 400px; overflow-y: scroll;' : ''}`"
          >
            <div class="col-12">
              <div
                v-if="products.length"
                class="form-row justify-content-beetwen mb-1"
              >
                <div class="col-md-9">
                  Produtos da categoria
                  <strong>{{ discount.category.title }}</strong>
                </div>
                <div class="col-md-3">
                  <input
                    type="checkbox"
                    class="d-none"
                    id="selectAllItems"
                    @click="selectAll"
                    v-model="allSelected"
                  />
                  <label for="selectAllItems">
                    <b-form-checkbox
                      v-model="allSelected"
                      @click="selectAll"
                      id="selectAllItems"
                    >
                      Selecionar todos
                    </b-form-checkbox>
                  </label>
                </div>
              </div>

              <b-list-group
                tag="ul"
                :list="products"
                class="list-group"
                handle=".handle"
              >
                <b-list-group-item
                  v-for="(element, index) in products"
                  :key="index"
                  class="list-group-item"
                >
                  <b-form-checkbox
                    v-model="discount.products"
                    @click="select"
                    :value="element.code"
                  >
                    <span class="pl-75">
                      {{ element.title }}
                    </span>
                  </b-form-checkbox>
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
        </b-card-text>

        <div class="row justify-content-end">
          <div class="col-md-3">
            <b-button
              block
              type="submit"
              variant="success"
              :disabled="submited"
            >
              <span v-if="submited">
                <b-spinner small v-if="submited"></b-spinner>
                aguarde...
              </span>
              <span v-else> Finalizar </span>
            </b-button>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { required } from "vuelidate/lib/validators";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";

import {
  BCard,
  BCardText,
  BFormCheckbox,
  BFormCheckboxGroup,
  BListGroup,
  BButton,
  BCol,
  BFormGroup,
  BForm,
  BRow,
  BListGroupItem,
  BAlert,
  BModal,
  BTable,
  BSpinner,
  BBadge,
} from "bootstrap-vue";

export default {
  components: {
    BCardText,
    BBadge,
    BCard,
    BTable,
    CustomPaginateTable,
    vSelect,
    BModal,
    BListGroup,
    BButton,
    BCol,
    BFormGroup,
    BForm,
    BSpinner,
    BRow,
    BListGroupItem,
    BFormCheckboxGroup,
    BAlert,
    BFormCheckbox,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      type: 1,
      loadingProducts: false,
      dataItems: [],
      currentPage: 1,
      perPage: 50,
      totalRows: 0,
      rowsTable: 0,
      fields: [
        {
          key: "title",
          label: "Título",
          class: "text-left",
        },
        {
          key: "discount",
          label: "Desconto",
          class: "text-center",
          thStyle: "width: 100px",
        },
        {
          key: "products",
          label: "Produtos",
          class: "text-center",
          thStyle: "width: 100px",
        },
        {
          key: "start",
          label: "Período",
          sortable: false,
          class: "text-center",
          thStyle: "width: 360px",
        },
        {
          key: "action",
          label: "",
          class: "text-center",
          thStyle: "width: 80px",
        },
      ],
      products: [],
      submited: false,
      optionsCategories: [],
      discount: {
        title: "",
        value: "",
        products: [],
        category: "",
        start: "",
        end: "",
      },
      selected: [],
      allSelected: false,
    };
  },
  beforeDestroy() {
    this.$root.$off("modal::discount");
  },
  watch: {
    discount: {
      handler() {
        if (this.discount.discount < 100) {
          this.discount.discount = this.discount.discount;
        } else {
          this.discount.discount;
        }
      },
    },
  },
  mounted() {
    this.getData();

    this.$root.$on("open:modal-create-coupon-mass", () => {
      this.discount = {
        title: "",
        value: "",
        products: [],
        category: "",
        start: "",
        end: "",
      };

      this.products = [];
      this.uuid = '';
      this.$bvModal.show("modal-create-coupon-mass");
    });

    this.$store.dispatch("Product/categories").then((res) => {
      this.optionsCategories = res.data;
    });

    this.$root.$on("paginate:update", (res) => {
      this.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.perPage = res._perPage;
      this.getData();
    });
  },
  methods: {
    openModalDetails(uuid) {
      this.uuid = uuid

      this.$store.dispatch("Product/showDiscount", uuid).then((res) => {
        this.discount = res;
        this.$bvModal.show("modal-create-coupon-mass");

        this.$store
          .dispatch("Product/allCategory", this.discount.category.code)
          .then((res) => {
            this.products = res;
          })
          .finally(() => {
            this.loadingProducts = false;
          });
      });
    },
    selectAll() {
      if (!this.allSelected) {
        this.products.forEach((element) => {
          this.discount.products.push(element.code);
        });
      } else {
        this.discount.products = [];
      }
    },
    select() {
      this.allSelected = true;
    },
    async getData() {
      this.$store
        .dispatch("Product/allDiscounts", {
          currentPage: this.currentPage,
          perPage: this.perPage,
        })
        .then((res) => {
          this.dataItems = res.data;
          this.rowsTable = res.data.length;
          this.totalRows = res.meta.total;
          this.currentPage = res.meta.current_page;
        })
        .finally(() => {
          this.submitedFilter = false;
        });
    },
    submitApplyDiscount() {
      this.$v.discount.$touch();
      this.submited = true;

      const data = {
        category: this.discount.category.code,
        products: this.discount.products,
        title: this.discount.title,
        discount: this.discount.discount,
        start: this.discount.start,
        end: this.discount.end,
      };

      if (!this.$v.discount.$error) {
        if (this.uuid) {
          this.$store
            .dispatch("Product/updateDiscount", { uuid: this.uuid, data: data })
            .then(() => {
              this.$bvModal.hide("modal-create-coupon-mass");
              this.notifyDefault("success");
              this.getData();

              this.discount = {
                title: "",
                value: "",
                products: [],
                period: "",
                category: "",
              };
            })
            .finally(() => {
              this.submited = false;
            });
        } else {
          this.$store
            .dispatch("Product/storeDiscount", data)
            .then(() => {
              this.$bvModal.hide("modal-create-coupon-mass");
              this.notifyDefault("success");
              this.getData();

              this.discount = {
                title: "",
                value: "",
                products: [],
                period: "",
                category: "",
              };
            })
            .finally(() => {
              this.submited = false;
            });
        }
      } else {
        this.submited = false;
      }
    },
    changeCategory(event) {
      if (event) {
        this.discount.products = [];
        this.loadingProducts = true;
        this.$store
          .dispatch("Product/allCategory", event.code)
          .then((res) => {
            this.products = res;
          })
          .finally(() => {
            this.loadingProducts = false;
          });
      }
    },
  },
  validations: {
    discount: {
      title: {
        required,
      },
      category: {
        required,
      },
      discount: {
        required,
      },
      start: {
        required,
      },
      end: {
        required,
      },
      products: {
        required,
      },
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
