var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-alert",
        {
          staticClass: "mb-2",
          attrs: { variant: "dark", show: "", "d-block": "" },
        },
        [
          _c("h4", { staticClass: "alert-heading" }, [
            _vm._v("Como funciona?"),
          ]),
          _c("div", { staticClass: "alert-body" }, [
            _vm._v(
              " O desconto será aplicado sobre o preço do produto. Atualmente, ao cadastrar o produto, existem 2 campos de precificação, que são: Preço e Promocional. Também serão cadastrados, o período promocional em cada produto. "
            ),
          ]),
        ]
      ),
      _c(
        "b-card",
        { staticClass: "mb-0", attrs: { "no-body": "" } },
        [
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.fields,
              "primary-key": "id",
              "show-empty": "",
              "empty-text": "Nenhum registro encontrado",
            },
            scopedSlots: _vm._u([
              {
                key: "cell(title)",
                fn: function (data) {
                  return [
                    _c("b-icon", {
                      class: data.item.active ? "text-success" : "text-danger",
                      staticStyle: { width: "9px" },
                      attrs: { icon: "circle-fill" },
                    }),
                    _vm._v(" " + _vm._s(data.item.title) + " "),
                  ]
                },
              },
              {
                key: "cell(products)",
                fn: function (data) {
                  return [
                    _c(
                      "b-badge",
                      {
                        staticClass: "d-block",
                        attrs: { variant: "light-secondary" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(JSON.parse(data.item.products).length) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "cell(discount)",
                fn: function (data) {
                  return [
                    _c(
                      "b-badge",
                      {
                        staticClass: "d-block",
                        attrs: { variant: "light-info" },
                      },
                      [_vm._v(" " + _vm._s(data.item.discount) + "% ")]
                    ),
                  ]
                },
              },
              {
                key: "cell(start)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("datePT")(data.item.start, true)) +
                        " à " +
                        _vm._s(_vm._f("datePT")(data.item.end, true)) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(action)",
                fn: function (data) {
                  return [
                    _vm.$can("Campanha - Editar", "Comercial")
                      ? _c("feather-icon", {
                          staticClass: "cursor-pointer cursor",
                          attrs: { icon: "EditIcon", size: "16" },
                          on: {
                            click: function ($event) {
                              return _vm.openModalDetails(data.item.uuid)
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("CustomPaginateTable", {
            attrs: {
              "rows-table": _vm.rowsTable,
              "current-page": _vm.currentPage,
              "per-page": _vm.perPage,
              "total-rows": _vm.totalRows,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-create-coupon-mass",
            title: "Agendar desconto em massa",
            "cancel-title-html": "Fechar",
            "no-close-on-backdrop": "",
            size: "lg",
            "hide-footer": "",
          },
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitApplyDiscount.apply(null, arguments)
                },
              },
            },
            [
              _c("b-card-text", [
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "col-md-3 col-6" }, [
                    _c("label", [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                      _vm._v(" Categoria "),
                    ]),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "v-select",
                          {
                            class: {
                              "is-invalid": _vm.$v.discount.category.$error,
                            },
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              "item-value": "id",
                              options: _vm.optionsCategories,
                            },
                            on: { input: _vm.changeCategory },
                            model: {
                              value: _vm.discount.category,
                              callback: function ($$v) {
                                _vm.$set(_vm.discount, "category", $$v)
                              },
                              expression: "discount.category",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-md-3 col-6" }, [
                    _c("label", [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                      _vm._v(" Desconto (%) "),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.discount.discount,
                            expression: "discount.discount",
                          },
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid": _vm.$v.discount.discount.$error,
                        },
                        attrs: { type: "number", max: "99" },
                        domProps: { value: _vm.discount.discount },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.discount,
                              "discount",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" Início da exibição "),
                        ]),
                        _c("flat-pickr", {
                          staticClass: "form-control",
                          class: { "is-invalid": _vm.$v.discount.start.$error },
                          attrs: {
                            config: {
                              dateFormat: "d/m/Y H:i",
                              altFormat: "Y-m-d",
                              locale: "pt",
                              enableTime: true,
                              defaultHour: 0,
                              minuteIncrement: 1,
                              time_24hr: true,
                              wrap: true,
                            },
                          },
                          model: {
                            value: _vm.discount.start,
                            callback: function ($$v) {
                              _vm.$set(_vm.discount, "start", $$v)
                            },
                            expression: "discount.start",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c("label", { attrs: { for: "" } }, [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                      _vm._v(" Fim da exibição "),
                    ]),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("flat-pickr", {
                          staticClass: "form-control",
                          class: { "is-invalid": _vm.$v.discount.end.$error },
                          attrs: {
                            config: {
                              dateFormat: "d/m/Y H:i",
                              locale: "pt",
                              enableTime: true,
                              defaultHour: 23,
                              defaultMinute: 59,
                              minuteIncrement: 1,
                              time_24hr: true,
                              wrap: true,
                            },
                          },
                          model: {
                            value: _vm.discount.end,
                            callback: function ($$v) {
                              _vm.$set(_vm.discount, "end", $$v)
                            },
                            expression: "discount.end",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("label", { attrs: { for: "" } }, [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                      _vm._v(" Título "),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.discount.title,
                            expression: "discount.title",
                          },
                        ],
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.$v.discount.title.$error },
                        attrs: { type: "text" },
                        domProps: { value: _vm.discount.title },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.discount, "title", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _vm.loadingProducts
                  ? _c(
                      "p",
                      [
                        _c("b-spinner", { attrs: { label: "Spinning" } }),
                        _vm._v(" Carregando produtos... "),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.loadingProducts
                  ? _c(
                      "div",
                      {
                        staticClass: "form-row",
                        class: _vm.products.length ? "mt-2" : "",
                        style:
                          "" +
                          (_vm.products.length
                            ? "max-height: 400px; overflow-y: scroll;"
                            : ""),
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _vm.products.length
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-row justify-content-beetwen mb-1",
                                  },
                                  [
                                    _c("div", { staticClass: "col-md-9" }, [
                                      _vm._v(" Produtos da categoria "),
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(_vm.discount.category.title)
                                        ),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "col-md-3" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.allSelected,
                                            expression: "allSelected",
                                          },
                                        ],
                                        staticClass: "d-none",
                                        attrs: {
                                          type: "checkbox",
                                          id: "selectAllItems",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.allSelected
                                          )
                                            ? _vm._i(_vm.allSelected, null) > -1
                                            : _vm.allSelected,
                                        },
                                        on: {
                                          click: _vm.selectAll,
                                          change: function ($event) {
                                            var $$a = _vm.allSelected,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.allSelected = $$a.concat(
                                                    [$$v]
                                                  ))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.allSelected = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.allSelected = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        { attrs: { for: "selectAllItems" } },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              attrs: { id: "selectAllItems" },
                                              on: { click: _vm.selectAll },
                                              model: {
                                                value: _vm.allSelected,
                                                callback: function ($$v) {
                                                  _vm.allSelected = $$v
                                                },
                                                expression: "allSelected",
                                              },
                                            },
                                            [_vm._v(" Selecionar todos ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "b-list-group",
                              {
                                staticClass: "list-group",
                                attrs: {
                                  tag: "ul",
                                  list: _vm.products,
                                  handle: ".handle",
                                },
                              },
                              _vm._l(_vm.products, function (element, index) {
                                return _c(
                                  "b-list-group-item",
                                  {
                                    key: index,
                                    staticClass: "list-group-item",
                                  },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        attrs: { value: element.code },
                                        on: { click: _vm.select },
                                        model: {
                                          value: _vm.discount.products,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.discount,
                                              "products",
                                              $$v
                                            )
                                          },
                                          expression: "discount.products",
                                        },
                                      },
                                      [
                                        _c("span", { staticClass: "pl-75" }, [
                                          _vm._v(
                                            " " + _vm._s(element.title) + " "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "row justify-content-end" }, [
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          block: "",
                          type: "submit",
                          variant: "success",
                          disabled: _vm.submited,
                        },
                      },
                      [
                        _vm.submited
                          ? _c(
                              "span",
                              [
                                _vm.submited
                                  ? _c("b-spinner", { attrs: { small: "" } })
                                  : _vm._e(),
                                _vm._v(" aguarde... "),
                              ],
                              1
                            )
                          : _c("span", [_vm._v(" Finalizar ")]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }